import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.navigator.geolocation.getCurrentPosition(this.updateCoordinates.bind(this))
  }

  updateCoordinates(position) {
    const url = `/update_coordinates`
    const userData = new FormData()

    userData.append('user[local_lat]', position.coords.latitude)
    userData.append('user[local_lon]', position.coords.longitude)

    const options = {
      method: "PATCH",
      headers: { "Accept": "application/json" },
      body: userData
    }
    fetchWithToken(url, options)
  }
}
