import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['first', 'second', 'third', 'signUp']
  connect() {
    const app = document.getElementsByClassName("scrollable")
    app[0].classList.remove("scrollable")
  }

  first() {
    this.firstTarget.classList.add("d-none")
    this.secondTarget.classList.remove("d-none")
  }

  second() {
    this.secondTarget.classList.add("d-none")
    this.thirdTarget.classList.remove("d-none")
  }

  third() {
    window.location.href = "/after_signup/onboarding_tutorial"

  }
}
