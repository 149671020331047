import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ 'messages', 'newMessageNotice' ]
  static values = {
    chatroomId: Number,
    userId: Number
  }

  connect() {
    this.channel = createConsumer().subscriptions.create(
      { channel: 'ChatroomChannel', id: this.chatroomIdValue },
      { received: this.#processMessage.bind(this) }
    )
    this.#scrollToBottom()
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  resetForm(event) {
    event.target.reset()
  }

  #processMessage(data) {
    const sender = this.userIdValue == data.user_id
    const msgElement = this.#buildHTML(data.html)
    if (!sender) msgElement.classList.remove('sender')

    this.#insertMessage(msgElement)

    if (sender) this.#scrollToBottom()
    if (!sender) {
      if (this.#scrolledToBottom()) {
        this.#scrollToBottom()
      } else {
        this.#noticeNewMessage()
        this.#addScrollListenerToMessages()
      }
    }
  }

  #buildHTML(string) {
    const tmpDiv = document.createElement('div')
    tmpDiv.innerHTML = string
    return tmpDiv.firstElementChild
  }

  #insertMessage(element) {
    this.messagesTarget.appendChild(element)
  }

  scrollToBottom() {
    this.#scrollToBottom()
  }

  #scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
  }

  #noticeNewMessage() {
    fadeShowElements(this.newMessageNoticeTarget)
  }

  #scrolledToBottom() {
    return this.messagesTarget.scrollTop + this.messagesTarget.clientHeight >= this.messagesTarget.scrollHeight - 1 - 120 // 120px fix
  }

  #addScrollListenerToMessages() {
    this.abortScrollListenerController = new AbortController();
    this.messagesTarget.addEventListener('scroll', this.#handleScrollAfterNewMessageNotice.bind(this), { signal: this.abortScrollListenerController.signal })
  }

  #removeScrollListenerToMessages() {
    this.abortScrollListenerController.abort()
  }

  #handleScrollAfterNewMessageNotice() {
    if (this.#scrolledToBottom()) {
      fadeHideElements(this.newMessageNoticeTarget)
      this.#removeScrollListenerToMessages()
    }
  }
}
