import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = [
    'birthDate',
    'birthHour',
    'crushBirthDate',
    'crushBirthHour',
  ]

  connect() {
    if (this.hasBirthDateTarget) {
      flatpickr(this.birthDateTarget, {
        dateFormat: "Y-m-d",
        disableMobile: "true"
      })
    }

    if (this.hasBirthHourTarget) {
      flatpickr(this.birthHourTarget, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        disableMobile: "true",
        time_24hr: true
      })
    }

    if (this.hasCrushBirthDateTarget) {
      flatpickr(this.crushBirthDateTarget, {
        dateFormat: "Y-m-d",
        disableMobile: "true"
      })
    }

    if (this.hasCrushBirthHourTarget) {
      flatpickr(this.crushBirthHourTarget, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        disableMobile: "true",
        time_24hr: true
      })
    }
  }
}
