import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'components/helpers'

import "trix"
import "@rails/actiontext"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
import $ from 'jquery'
window.$ = $
window.jQuery = $

import "controllers"
import "bootstrap"

import { Swiper } from '../components/swiper'

document.addEventListener('turbolinks:load', () => {
  const list = document.querySelectorAll('.list');
  if (list) {
    function activeLink(){
      list.forEach((item)=>
      item.classList.remove('active'));
      this.classList.add('active');
    }
    list.forEach((item) =>
    item.addEventListener('click',activeLink));
  }
})

// $(document).on('turbolinks:load', function(){ $.rails.refreshCSRFTokens(); });

require("trix")
require("@rails/actiontext")
