import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'theme',
    'score',
    'articles',
    'new',
    'beta',
    'connexion',
    'logout'
  ]
  connect() {
    if (window.location.pathname === '/theme') {
      this.themeTarget.classList.add('link-active')
    }
    if (window.location.pathname === '/score') {
      this.scoreTarget.classList.add('link-active')
    }
    if (window.location.pathname === '/articles') {
      this.articlesTarget.classList.add('link-active')
    }
    if (window.location.pathname === '/articles/new') {
      this.newTarget.classList.add('link-active')
    }
  }
  updateLandingNavbar() {
    if (window.scrollY >= 50) {
      this.element.classList.add("landing-navbar-purple")
    } else {
      this.element.classList.remove("landing-navbar-purple")
    }
  }
}
