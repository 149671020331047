import { Controller } from "@hotwired/stimulus"
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = [
    'input',
    'minInput',
    'maxInput',
  ]

  static values = {
    min: Number,
    selectedMin: Number,
    max: Number,
    selectedMax: Number
  }

  connect() {
    noUiSlider.create(this.inputTarget, {
      start: [this.selectedMinValue, this.selectedMaxValue],
      connect: true,
      margin: 1,
      step: 1,
      range: {
        'min': this.minValue,
        'max': this.maxValue
      }
    })
    this.inputTarget.noUiSlider.on('set', this.slide.bind(this))
    this.inputTarget.noUiSlider.on('slide', this.slide.bind(this))
  }

  slide(e) {
    const min = roundToDecimal(e[0])
    const max = roundToDecimal(e[1])
    const inputEvent = new Event('input')
    this.minInputTarget.value = min
    this.maxInputTarget.value = max
    this.minInputTarget.dispatchEvent(inputEvent)
    this.maxInputTarget.dispatchEvent(inputEvent)
  }
}
