import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "horoscope",
    "theme",
    "score",
    "table",
    "wheel",
    "horoscope_today",
    "horoscope_tomorrow",
    "horoscope_menu",
    "theme_menu",
    "score_menu",
    "table_menu",
    "wheel_menu",
    "horoscope_today_menu",
    "horoscope_tomorrow_menu",
    "scoreCalculationMenu",
    "scoreCalculation",
    "matchCalculationsMenu",
    "matchCalculations"
  ]

  hover(target) {
    target.classList.add("nav-hov")
    if (target !== this.horoscope_menuTarget) {
      this.horoscope_menuTarget.classList.remove("nav-hov")
    }
    if (target !== this.theme_menuTarget) {
      this.theme_menuTarget.classList.remove("nav-hov")
    }
    if (target !== this.score_menuTarget) {
      this.score_menuTarget.classList.remove("nav-hov")
    }
    if (target !== this.horoscope_today_menuTarget) {
      this.horoscope_today_menuTarget.classList.remove("nav-hov")
    }
    if (target !== this.horoscope_tomorrow_menuTarget) {
      this.horoscope_tomorrow_menuTarget.classList.remove("nav-hov")
    }
  }

  horoscope() {
    this.hover(this.horoscope_menuTarget)
    this.horoscopeTarget.classList.remove("d-none");
    this.horoscope_menuTarget.classList.add("lead");

    this.themeTarget.classList.add("d-none");
    this.theme_menuTarget.classList.remove("lead");

    this.scoreTarget.classList.add("d-none");
    this.score_menuTarget.classList.remove("lead");

    this.horoscope_today_menuTarget.classList.add("horo-lead");
    this.horoscope_tomorrow_menuTarget.classList.remove("horo-lead");
    this.horoscope_tomorrowTarget.classList.add("d-none");
    this.horoscope_todayTarget.classList.remove("d-none");

  }

  theme() {
    this.hover(this.theme_menuTarget)
    this.themeTarget.classList.remove("d-none");
    this.theme_menuTarget.classList.add("lead");

    this.horoscopeTarget.classList.add("d-none");
    this.horoscope_menuTarget.classList.remove("lead");

    this.scoreTarget.classList.add("d-none");
    this.score_menuTarget.classList.remove("lead");
  }

  wheel() {
    this.wheel_menuTarget.classList.add("horo-lead");
    this.table_menuTarget.classList.remove("horo-lead");

    this.wheelTarget.classList.remove("d-none");
    this.tableTarget.classList.add("d-none");
  }

  table() {
    this.table_menuTarget.classList.add("horo-lead");
    this.wheel_menuTarget.classList.remove("horo-lead");

    this.tableTarget.classList.remove("d-none");
    this.wheelTarget.classList.add("d-none");
    }

  score() {
    this.hover(this.score_menuTarget)
    this.scoreTarget.classList.remove("d-none");
    this.score_menuTarget.classList.add("lead");

    this.horoscopeTarget.classList.add("d-none");
    this.horoscope_menuTarget.classList.remove("lead");

    this.themeTarget.classList.add("d-none");
    this.theme_menuTarget.classList.remove("lead");
  }

  horoscope_today() {
    this.hover(this.horoscope_today_menuTarget)
    this.horoscope_todayTarget.classList.remove("d-none");
    this.horoscope_today_menuTarget.classList.add("horo-lead");

    this.horoscope_tomorrowTarget.classList.add("d-none");
    this.horoscope_tomorrow_menuTarget.classList.remove("horo-lead");
  }

  horoscope_tomorrow() {
    this.hover(this.horoscope_tomorrow_menuTarget)
    this.horoscope_tomorrowTarget.classList.remove("d-none");
    this.horoscope_tomorrow_menuTarget.classList.add("horo-lead");

    this.horoscope_todayTarget.classList.add("d-none");
    this.horoscope_today_menuTarget.classList.remove("horo-lead");
  }

  scoreCalculation() {
    this.scoreCalculationTarget.classList.remove("d-none");
    this.scoreCalculationMenuTarget.classList.add("horo-lead");

    this.matchCalculationsTarget.classList.add("d-none");
    this.matchCalculationsMenuTarget.classList.remove("horo-lead");
  }

  matchCalculations() {
    this.matchCalculationsTarget.classList.remove("d-none");
    this.matchCalculationsMenuTarget.classList.add("horo-lead");

    this.scoreCalculationTarget.classList.add("d-none");
    this.scoreCalculationMenuTarget.classList.remove("horo-lead");
  }
}
