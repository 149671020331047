import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    this.count = this.checkboxTargets.filter(e => e.checked).length
    if (this.count >= 5) this.disableAll()
  }

  validate(event) {
    if (this.count >= 5 && event.target.checked) {
      event.target.checked = false
      return
    }

    event.target.checked ? this.count++ : this.count--

    if (this.count >= 5) {
      this.disableAll()
    } else {
      this.ableAll()
    }
  }

  disableAll() {
    this.checkboxTargets.filter(e => !e.checked).forEach((e) => {
      e.closest('.interests-item').classList.add('disabled')
    })
  }

  ableAll() {
    this.checkboxTargets.forEach((e) => {
      e.closest('.interests-item').classList.remove('disabled')
    })
  }
}
