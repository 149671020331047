import { Controller } from "@hotwired/stimulus"
import { Modal } from "../components/modal"

export default class extends Controller {
  static targets = [
    "birthDate",
    "birthHour",
    "locationInput",
    "name",
    "latitude",
    "longitude",
    "gender",
    'modalTemplate',
    'matchCalculationsList',
    'matchCalculationsListBtn',
    'emptyMatchCalculationListNotice'
  ];
  static values = {
    currentBirthDate: String,
    currentBirthHour: String,
    currentLatitude: Number,
    currentLongitude: Number,
    currentGender: Number,
    currentTimezone: Number,
    userId: String,
    apiKey: String,
    saveUrl: String,
    rootUrl: String
  };

  connect = () => {
    this.modal = new Modal()
    this.#initAutocomplete()
  }

  matchPercentage = (event) => {
    event.preventDefault()

    var crushData = this.#mateData()
    var currentData = this.#currentUserData()

    this.#setTimeZone(crushData)
      .then(resp => this.setGender(resp, currentData))
      .then(resp => this.#getMatchPercentage(resp))
      .catch(err => this.#failureCallback(err))
  }

  save(event) {
    const url = this.saveUrlValue
    const data = new FormData()

    data.append('match_calculation[name]', this.matchCalculationName)
    data.append('match_calculation[score]', this.matchCalculationScore)
    data.append('match_calculation[dob]', this.matchCalculationDob)
    data.append('match_calculation[hob]', this.matchCalculationHob)
    data.append('match_calculation[location]', this.matchCalculationLoc)
    data.append('match_calculation[lat]', this.matchCalculationLat)
    data.append('match_calculation[lng]', this.matchCalculationLng)

    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: data
    }

    fetchWithToken(url, options)
      .then(response => response.json())
      .then((data) => {
        this.modal.close()
        if (data.errors) {
          console.log(data.errors)
        } else {
          if (this.hasEmptyMatchCalculationListNoticeTarget) this.emptyMatchCalculationListNoticeTarget.remove()
          this.matchCalculationsListTarget.insertAdjacentHTML('afterbegin', data.match_calculation_html)
          this.matchCalculationsListBtnTarget.click()
        }
      })
  }

  share(event) {
    const data = {
      // url: A string representing a URL to be shared.
      // text: A string representing text to be shared.
      // title: A string representing a title to be shared. May be ignored by the target.
      // files: An array of File objects representing files to be shared. See below for shareable file types.
      title: I18n.t('matching_between', {mate: this.matchCalculationName}),
      text: I18n.t('sharing_text', {mate: this.matchCalculationName, score: this.matchCalculationScore}),
      url: this.rootUrlValue
    }
    if (navigator.share) {
      navigator.share(data)
        .then(() => { console.log('Thanks for sharing!') })
        .catch(console.error)
    } else {
      console.log(data)
    }
  }

  #setTimeZone = (crushData) => {
    const resource = 'timezone_with_dst';
    const data = {
      'latitude': crushData['lat'],
      'longitude': crushData['lon'],
      'date': `${crushData['month']}-${crushData['day']}-${crushData['year']}`,
    };

    return new Promise((resolve, reject) => {
      this.#request(resource, data).then((resp) => {
        crushData['tzone'] = parseFloat(resp['timezone']);
        resolve(crushData);
      }, (err) => {
        reject(err);
      });
    });
  };

  setGender = (crushData, currentData) => {
    const mData = {};
    const fData = {};

    return new Promise((resolve, reject) => {
      if(this.currentGenderValue === 1){
        Object.entries(currentData).forEach(([k, v]) => {
          const el = {[`m_${k}`]: v};
          Object.assign(mData, el);
        });
        Object.entries(crushData).forEach(([k, v]) => {
          const el = {[`f_${k}`]: v};
          Object.assign(fData, el);
        });
      } else {
        Object.entries(crushData).forEach(([k, v]) => {
          const el = {[`m_${k}`]: v};
          Object.assign(mData, el);
        });
        Object.entries(currentData).forEach(([k, v]) => {
          const el = {[`f_${k}`]: v};
          Object.assign(fData, el);
        });
      }
      resolve({ ...mData, ...fData }), (err) => {
        reject(err);
      };
    });
  };

  #getMatchPercentage = (data) => {
    const resource = 'match_percentage';

    this.#request(resource, data).then((resp) => {
      const clone = this.modalTemplateTarget.content.cloneNode(true);

      this.matchCalculationName = this.nameTarget.value
      this.matchCalculationScore = resp.match_percentage
      this.matchCalculationDob = this.birthDateTarget.value
      this.matchCalculationHob = this.birthHourTarget.value
      this.matchCalculationLoc = this.locationInputTarget.value
      this.matchCalculationLat = this.latitudeTarget.value
      this.matchCalculationLng = this.longitudeTarget.value

      clone.querySelector(".score-name").innerText = `${this.#capitalizeFirstLetter(this.nameTarget.value)}`;
      clone.querySelector(".astroscore").insertAdjacentHTML('afterbegin', `${resp.match_percentage}`)
      clone.querySelector('.score-close').addEventListener('click', () => this.modal.close())
      clone.querySelector('.save_score_button').addEventListener('click', this.save.bind(this))
      clone.querySelector('.share_score_button').addEventListener('click', this.share.bind(this))

      this.modal.replaceContent({html: clone})
      this.modal.open()
    }, (err) => {
      console.log(err);
    })
  };

  #failureCallback = (err) => console.log(err);

  #request = (resource, data) => {
    const userId = this.userIdValue;
    const apiKey = this.apiKeyValue;

    return $.ajax({
      url: "https://json.astrologyapi.com/v1/"+resource,
      method: "POST",
      dataType:'json',
      headers: {
        "authorization": "Basic " + btoa(userId+":"+apiKey),
        "Content-Type":'application/json'
      },
      data:JSON.stringify(data)
    });
  };

  #initAutocomplete = () => {
    if (typeof(google) === undefined) return

    this.autocomplete = new google.maps.places.Autocomplete(this.locationInputTarget);
    this.autocomplete.setFields(['address_components', 'geometry', 'name', 'utc_offset_minutes']);
    this.autocomplete.addListener('place_changed', this.#placeChanged.bind(this));
  };

  #placeChanged = () => {
    let place = this.autocomplete.getPlace();
    this.latitudeTarget.value = place.geometry.location.lat();
    this.longitudeTarget.value = place.geometry.location.lng();
  };

  #capitalizeFirstLetter = (string) => {
   return string.charAt(0).toUpperCase() + string.slice(1);
  }

  #currentUserData() {
    return {
      'day': parseInt(this.currentBirthDateValue.split('-')[2]),
      'month': parseInt(this.currentBirthDateValue.split('-')[1]),
      'year': parseInt(this.currentBirthDateValue.split('-')[0]),
      'hour': parseInt(this.currentBirthHourValue.split(' ')[1].split(':')[0]),
      'min': parseInt(this.currentBirthHourValue.split(' ')[1].split(':')[1]),
      'lat': parseFloat(this.currentLatitudeValue),
      'lon': parseFloat(this.currentLongitudeValue),
      'tzone': parseFloat(this.currentTimezoneValue)
    }
  }

  #mateData() {
    return {
      'day': parseInt(this.birthDateTarget.value.split('-')[2]),
      'month': parseInt(this.birthDateTarget.value.split('-')[1]),
      'year': parseInt(this.birthDateTarget.value.split('-')[0]),
      'hour': parseInt(this.birthHourTarget.value.split(':')[0]),
      'min': parseInt(this.birthHourTarget.value.split(':')[1]),
      'lat': parseFloat(this.latitudeTarget.value),
      'lon': parseFloat(this.longitudeTarget.value),
      'tzone': null
    }
  }
};
