import { Controller } from "@hotwired/stimulus"
import { I18n } from "i18n-js";
import translations from "../locales/translations.json";


export default class extends Controller {
  static values = { locale: String }

  connect() {
    const i18n = new I18n(translations);
    i18n.defaultLocale = "fr";
    i18n.locale = this.localeValue;
    window.I18n = i18n;
  }
}
