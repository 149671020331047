import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'day',
    'hour',
    'minute',
    'seconde'
  ]

  static values = {
    startDate: String,
    endDate: String,
    url: String,
  }

  connect() {
    if (this.startDate > new Date()) {
      this.refDate = this.startDate
      this._start()
    } else if (this.endDate > new Date()) {
      this.refDate = this.endDate
      this._start()
    }
  }

  disconnect() {
    this._stop()
  }

  _start() {
    if (!this.hasDayTarget) return

    this.run = setInterval(this._counter.bind(this), 1000)
  }

  _stop() {
    clearInterval(this.run)
  }

  _counter() {
    this.now = new Date()
    this.timeLeft = (this.refDate - this.now) / 1000

    if (this.timeLeft > 0) {
      const nbDays = Math.floor(this.timeLeft / (60 * 60 * 24))
      const nbHours = Math.floor((this.timeLeft - (nbDays * 60 * 60 * 24)) / (60 * 60))
      const nbMinutes = Math.floor((this.timeLeft - ((nbDays * 60 * 60 * 24 + nbHours * 60 * 60))) / 60);
      const nbSecondes = Math.floor(this.timeLeft - ((nbDays * 60 * 60 * 24 + nbHours * 60 * 60 + nbMinutes * 60)));

      this.dayTarget.innerHTML = this._count(nbDays)
      this.hourTarget.innerHTML = this._count(nbHours)
      this.minuteTarget.innerHTML = this._count(nbMinutes)
      this.secondeTarget.innerHTML = this._count(nbSecondes)
    } else {
      this._fetchResults()
    }
  }

  _count(nb) {
    return (nb < 10) ? '0' + nb : nb;
  }

  _fetchResults() {
    fetch(this.urlValue, {
      method: 'GET',
      headers: { "Accept": "application/html" },
    })
      .then(response => response.text())
      .then(data => {
        const html = document.createElement('html')
        html.innerHTML = data
        const newContent = html.querySelector('#ehOnEstPasDesBetes')
        this._stop()
        this.element.innerHTML = newContent.outerHTML
      })
      .catch((error) => {
        console.error(error);
      })
  }

  get startDate() {
    const isoStringDate = this.startDateValue.replace(' ', 'T').replace(/\s*(\+\d{2})(\d{2})/, '$1:$2');
    return new Date(isoStringDate)
  }

  get endDate() {
    const isoStringDate = this.endDateValue.replace(' ', 'T').replace(/\s*(\+\d{2})(\d{2})/, '$1:$2');
    return new Date(isoStringDate)
  }
}
