import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      modules: [Navigation, Pagination],
      spaceBetween: 8,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
    });
    this.#preventScrollOnMobileTouch()
  }

  #preventScrollOnMobileTouch() {
    if (this.swiper.slides.length > 1) {
      this.#getPreviousValues()
      this.swiper.on('sliderMove', this.#disableScroll.bind(this))
      this.swiper.on('slideChangeTransitionEnd', this.#enableScroll.bind(this))
      this.swiper.on('slideResetTransitionEnd', this.#enableScroll.bind(this))
    }
  }

  #getPreviousValues() {
    this.previousBodyOverflowValue = document.body.style.overflow
    const astroModalBody = this.element.closest('.astro-modal-body')
    if (astroModalBody) {
      this.previousAstroModalBodyOverflowValue = this.element.closest('.astro-modal-body').style.overflow
    }
  }

  #enableScroll() {
    document.body.style.overflow = this.previousBodyOverflowValue
    this.#setAstroModalBodyScrollValue(this.previousAstroModalBodyOverflowValue)
  }

  #disableScroll() {
    document.body.style.overflow = 'hidden'
    this.#setAstroModalBodyScrollValue('hidden')
  }

  #setAstroModalBodyScrollValue(value) {
    const astroModalBody = this.element.closest('.astro-modal-body')
    if (astroModalBody) astroModalBody.style.overflow = value
  }
}

