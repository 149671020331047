import { Controller } from '@hotwired/stimulus'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    'input',
    'photo',
    'cancelButton',
    'deleteButton',
    'saveButton'
  ]

  static values = {
    updateUrl: String,
    originalImageSrc: String,
    defaultImageSrc: String,
    class: String,
    style: String,
    detachUrl: String,
    persisted: Boolean
  }

  connect() {
    this.#setup()
  }

  #setup() {
    this.deleted  = false
    this.reader   = new FileReader();
    if (this.inputTarget.files[0])  this.displayImage()
    if (this.persistedValue)        this.deleteButtonTarget.classList.remove('d-none')
  }

  displayImage() {
    this.reader.onload = (ev) => {
      this.photoTarget.src = ev.currentTarget.result
      this.#showElements(this.cancelButtonTarget, this.saveButtonTarget)
    }
    this.reader.readAsDataURL(this.inputTarget.files[0])
  }

  openFileExplorerWindow() {
    this.inputTarget.click()
  }

  dropFile(event) {
    var list = new DataTransfer();
    if (event.dataTransfer.items) {
      var file = event.dataTransfer.items[0].getAsFile()
    } else {
      var file = event.dataTransfer.files[0]
    }
    list.items.add(file)

    this.inputTarget.files = list.files
  }

  cancel() {
    this.photoTarget.src = this.deleted ? this.defaultImageSrcValue : this.originalImageSrcValue
    this.inputTarget.value = ''
    this.#hideElements(this.cancelButtonTarget, this.saveButtonTarget)
  }

  save(event) {
    console.log(event)
    this.saveButtonTarget.classList.add('active')
    const url = this.updateUrlValue
    const data = new FormData
    data.append('photo[file]', this.inputTarget.files[0])
    data.append('photo[class]', this.classValue)
    data.append('photo[style]', this.styleValue)
    const options = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json'
      },
      body: data
    }

    this.saveButtonTarget.classList.add('active')
    this.#fetchWithToken(url, options)
      .then(response => response.json())
      .then((data) => {
        this.saveButtonTarget.classList.remove('active')
        if (data.html) this.element.outerHTML = data.html
      })

  }

  delete({ params }) {
    Swal.fire({
      title: `Do you want to remove the attached photo on this image?`,
      text: `You will permanently remove the photo and the image will be empty`,
      icon: 'warning',
      confirmButtonColor: '#FFBABA',
      confirmButtonText: 'Remove',
      showCancelButton: true,
      cancelButtonText: 'Cancel removal'
    }).then((result) => {
      if (result.isConfirmed) this.#requestDelete(params.detachUrl)
    })
  }

  #requestDelete(url) {
    const data = new FormData
    data.append('photo[class]', this.classValue)
    data.append('photo[style]', this.styleValue)
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      },
      body: data
    }

    this.deleteButtonTarget.classList.add('active')
    this.#fetchWithToken(url, options)
      .then(response => response.json())
      .then(this.#handleDeletion.bind(this))
  }

  #fetchWithToken(url, options) {
    options.headers = {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      ...options.headers
    };

    return fetch(url, options)
  }

  #handleDeletion(data) {
    if (data.html) this.element.outerHTML = data.html
  }

  #showElements() {
    Array.from(arguments).flat().forEach((e) => {
      e.classList.remove('hidden')
    })
  }

  #hideElements() {
    Array.from(arguments).flat().forEach((e) => {
      e.classList.add('hidden')
    })
  }
}
