import { Controller } from 'stimulus';
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = [
    'formContainer',
    'currentlyCalculatingInfosContainer',
    'form',
    'step',
    'astralThemeResultsContainer'
  ]
  static values = { id: String }

  connect() {
    this._subscribe()
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()
    const url = this.formTarget.action
    const options = {
      method: this.formTarget.method,
      headers: { "Accept": "application/json" },
      body: new FormData(this.formTarget)
    }

    fetchWithToken(url, options)
      .then(response => response.json())
      .then(this._handleFormSubmissionData.bind(this))
      .catch((error) => {
        console.error(error);
      })
  }

  allowNextStep() {
    // TODO validate that at least one checkbox is selected for looking for step, validate place input has been populated
    const currentStep = this._currentStep()
    const inputs = currentStep.querySelectorAll('input:not([type="hidden"]), textarea')
    const valid = Array.from(inputs).every(input => input.value && input.value.length > 0 )
    const nextBtn = currentStep.querySelector('.btn-event-form-next')
    nextBtn.disabled = !valid
  }

  nextStep(event) {
    event.preventDefault()
    const currentStep = this._currentStep()
    const nextStep = this._nextStep()
    currentStep.classList.add('d-none')
    nextStep.classList.remove('d-none')
  }

  previousStep(event) {
    event.preventDefault()
    const currentStep = this._currentStep()
    const previousStep = this._previousStep()
    currentStep.classList.add('d-none')
    previousStep.classList.remove('d-none')
  }

  _subscribe() {
    this.channel = consumer.subscriptions.create(
      { channel: "Event::SignCalculationChannel", session_id: this.idValue },
      { received: this._received.bind(this) }
    )
  }

  _received(data) {
    if (data.html) this.astralThemeResultsContainerTarget.innerHTML = data.html
  }

  _handleFormSubmissionData(data) {
    if (data.errors) {
      const htmlString = data.form;
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const form = doc.querySelector("#new_event_user");

      form.querySelectorAll('section').forEach(section => section.classList.add('d-none'))
      form.querySelector('input.error, textarea.error').closest('section').classList.remove('d-none')

      this.formTarget.outerHTML = form.outerHTML
    } else {
      this.formContainerTarget.classList.add('d-none')
      this.currentlyCalculatingInfosContainerTarget.classList.remove('d-none')
    }
  }

  _currentStep() {
    return this.stepTargets.find((step) => !step.classList.contains('d-none'))
  }

  _nextStep() {
    const currentStep = this._currentStep()
    const currentStepIndex = this.stepTargets.indexOf(currentStep)
    if (currentStepIndex === -1 || currentStepIndex === this.stepTargets.length - 1) return this.stepTargets[this.stepTargets.length - 1]

    const nextStep = this.stepTargets[currentStepIndex + 1]
    return nextStep
  }

  _previousStep() {
    const currentStep = this._currentStep()
    const currentStepIndex = this.stepTargets.indexOf(currentStep)
    if (currentStepIndex === -1 || currentStepIndex === 0) return this.stepTargets[0]

    const previousStep = this.stepTargets[currentStepIndex - 1]
    return previousStep
  }
}
