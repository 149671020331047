import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "minimalAgePlace",
    "maximumAgePlace",
    "minimalAge",
    "maximumAge",
    "distance",
    "distancePlace"
  ]

  updateValue() {
    this.minimalAgePlaceTarget.innerHTML = this.minimalAgeTarget.value
    this.maximumAgePlaceTarget.innerHTML = this.maximumAgeTarget.value
    this.distancePlaceTarget.innerHTML = this.distanceTarget.value
  }
};
