import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'card'
  ]

  connect() {
    document.addEventListener('card_swiper:liked', this.like.bind(this))
    document.addEventListener('card_swiper:disliked', this.dislike.bind(this))
  }

  disconnect() {
    document.removeEventListener('card_swiper:liked', this.like.bind(this))
    document.removeEventListener('card_swiper:disliked', this.dislike.bind(this))
  }

  like(event) {
    this.requestMatch(true, event.detail)
  }

  dislike(event) {
    this.requestMatch(false, event.detail)
  }

  requestMatch(liked, id) {
    const url  = `/matches`
    const data = new FormData()

    data.append('match[mate_id]', id)
    data.append('liked', liked)

    const options = {
      method: "POST",
      body: data
    }

    fetchWithToken(url, options)
      .then(response => response.json())
      .then(this.responseHandler.bind(this))
  }

  responseHandler(data) {
    this.cardTargets.find(e => e.dataset.id == data.user_id).remove()
  }
}
