import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    this.channel = createConsumer().subscriptions.create(
      { channel: "Noticed::NotificationChannel" },
      { received: this.#processNotification.bind(this) }
    )
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  #processNotification(data) {
    if (data.url === window.location.pathname) {
      this.#destroyNotification(data)
    } else {
      this.#createFlash(data)
      this.#navbarNotify()
      this.#chatroomsNotify(data)
    }
  }

  #navbarNotify() {
    document.querySelector('.navbar-message-icon').classList.add('notification-dot')
  }

  #chatroomsNotify(data) {
    const chatroomCard = document.querySelector(`.chatroom-message-notification-${data.chatroom_id}`)
    if (chatroomCard) chatroomCard.classList.add('notification-dot')
  }

  #createFlash(data) {
    const placeholder = document.createElement('div')
    const flash     = document.createElement('div')
    const content   = document.createElement('div')
    const close     = document.createElement('span')
    const text      = document.createElement('span')
    const link      = document.createElement('a')

    placeholder.classList.add('flash-placeholder', 'grow-in')

    flash.classList.add('flash', 'flash-notice', 'slide-in')
    flash.setAttribute('data-controller', 'flash')
    flash.setAttribute('data-action', 'mouseover->flash#stopTimeout mouseleave->flash#startTimeOut click->flash#restartTimeout')

    content.classList.add('flash-content')

    close.classList.add('flash-close')
    close.setAttribute('data-action', 'click->flash#remove')

    text.classList.add('flash-message')

    link.setAttribute('href', data.url)
    link.setAttribute('style', 'margin-left: auto;')
    link.innerHTML = '<i class="fa-solid fa-eye"></i>'

    text.innerText  = `${data.content} `
    close.innerHTML = this.#closeSvg()
    content.insertAdjacentElement('afterbegin', close)
    content.insertAdjacentElement('beforeend', text)
    content.insertAdjacentElement('beforeend', link)
    flash.insertAdjacentElement('afterbegin', content)
    placeholder.insertAdjacentElement('afterbegin', flash)

    document.querySelector('.flashes-container').insertAdjacentElement('afterbegin', placeholder)
  }

  #closeSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>`
  }

  #destroyNotification(data) {
    const url = `/notifications/${data.id}`
    const options = {
      method: 'DELETE'
    }
    fetchWithToken(url, options)
  }
}
