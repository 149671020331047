import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['box', 'add', 'src']
  static values = {
    photoId: Number
  }

  showPreview = (e) => {
    e.preventDefault()
    const box = this.boxTarget
    const add = this.addTarget
    const pouet = this.srcTarget
    const idValue =this.photoIdValue
    console.log(this.context.targetObserver)
    if (e.target.files.length > 0) {
      const src = URL.createObjectURL(e.target.files[0])
      const preview = pouet
      preview.src = src
      preview.style.display = "block"
      add.classList.add('d-none')
    }
  }
}
