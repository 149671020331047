import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = ['input', 'country', 'city', 'utcoffset', 'latitude', 'longitude', 'submitBtn']
  static values  = {}

  connect() {
    this.initAutocomplete()
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, this.autocompleteOptions)
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace()

    this.setData()
    this.setValues()
  }

  setData() {
    this.placeData = {}

    this.place.address_components.forEach((el) => { this.placeData[el.types[0]] = el.long_name })
    this.placeData.address = this.place.formatted_address
    this.placeData.lng     = this.place.geometry.location.lng()
    this.placeData.lat     = this.place.geometry.location.lat()
    this.placeData.utc     = this.place.utc_offset_minutes / 60

    if (this.hasSubmitBtnTarget) this.submitBtnTarget.disabled = !this.place
    return this.placeData
  }

  setValues() {
    this.longitudeTarget.value = this.placeData.lng
    this.latitudeTarget.value  = this.placeData.lat
    this.cityTarget.value      = this.placeData.locality
    this.countryTarget.value   = this.placeData.country
    this.utcoffsetTarget.value = this.placeData.utc
  }

}
