import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    "name",
    "crushName",
    "birthDate",
    "crushBirthDate",
    "birthHour",
    "crushBirthHour",
    "latitude",
    "longitude",
    "crushLatitude",
    "crushLongitude",
    "locationInput",
    "crushLocationInput",
    'scoreContainer',
    'email',
  ]

  static values = {
    userId: String,
    apiKey: String,
    saveUrl: String,
    scoreUrl: String,
    themeUrl: String,
    rootUrl: String,
  }

  connect() {
    this.#initAutocomplete()
  }

  #initAutocomplete() {
    if (typeof(google) === undefined) return

    this.autocomplete = new google.maps.places.Autocomplete(this.locationInputTarget)
    this.autocomplete.setFields(['address_components', 'geometry', 'name', 'utc_offset_minutes'])
    this.autocomplete.addListener('place_changed', this.#placeChanged.bind(this))

    this.crushAutocomplete = new google.maps.places.Autocomplete(this.crushLocationInputTarget)
    this.crushAutocomplete.setFields(['address_components', 'geometry', 'name', 'utc_offset_minutes'])
    this.crushAutocomplete.addListener('place_changed', this.#crushPlaceChanged.bind(this))
  }

  #placeChanged() {
    let place = this.autocomplete.getPlace().geometry.location
    this.latitudeTarget.value = place.lat()
    this.longitudeTarget.value = place.lng()
  }

  #crushPlaceChanged() {
    let place = this.crushAutocomplete.getPlace().geometry.location
    this.crushLatitudeTarget.value = place.lat()
    this.crushLongitudeTarget.value = place.lng()
  }

  matchPercentage(event) {
    event.preventDefault()

    this.name = this.nameTarget.value
    this.crushName = this.crushNameTarget.value

    const userData = {
      'day': parseInt(this.birthDateTarget.value.split('-')[2]),
      'month': parseInt(this.birthDateTarget.value.split('-')[1]),
      'year': parseInt(this.birthDateTarget.value.split('-')[0]),
      'hour': parseInt(this.birthHourTarget.value.split(':')[0]),
      'min': parseInt(this.birthHourTarget.value.split(':')[1]),
      'lat': parseFloat(this.latitudeTarget.value),
      'lon': parseFloat(this.longitudeTarget.value),
      'tzone': null
    }

    const crushData = {
      'day': parseInt(this.crushBirthDateTarget.value.split('-')[2]),
      'month': parseInt(this.crushBirthDateTarget.value.split('-')[1]),
      'year': parseInt(this.crushBirthDateTarget.value.split('-')[0]),
      'hour': parseInt(this.crushBirthHourTarget.value.split(':')[0]),
      'min': parseInt(this.crushBirthHourTarget.value.split(':')[1]),
      'lat': parseFloat(this.crushLatitudeTarget.value),
      'lon': parseFloat(this.crushLongitudeTarget.value),
      'tzone': null
    }

    if (this.#validData(userData, crushData)) {
      this.#setTimeZone(userData)
      .then(() => this.#setTimeZone(crushData))
      .then(() => this.setGender(userData, crushData))
      .then(this.#calculMatchPercentage.bind(this))
      .catch(err => console.error(err))
    }
  }


  #validData(userData, crushData) {
    const arr = []
    const h = [userData, crushData]
    h.map(data => {
      Object.entries(data).map(([k, v]) => {
        if (k !== 'tzone') {
          if (isNaN(v)) {
            arr.push(1)
          }
         }
      })
    })

    if (arr.length > 0) {
      Swal.fire({
        title: 'Veuillez renseigner tous les champs',
        text: 'si vous ne connaissez pas une heure de naissance vous pouvez indiquer midi',
        icon: 'warning',
        confirmButtonColor: '#FFBABA',
        confirmButtonText: 'Ok'
      })
      return false
    } else {
      return true
    }
  }

  #setTimeZone(data) {
    const resource = 'timezone_with_dst'
    const arr = {
      'latitude': data['lat'],
      'longitude': data['lon'],
      'date': `${data['month']}-${data['day']}-${data['year']}`
    }

    return new Promise((resolve, reject) => {
      this.#request(resource, arr).then((resp) => {
        data['tzone'] = parseFloat(resp['timezone'])
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }

  setGender(userData, crushData) {
    const mData = {}
    const fData = {}

    return new Promise((resolve, reject) => {
        Object.entries(crushData).forEach(([k, v]) => {
          const el = {[`m_${k}`]: v}
          Object.assign(mData, el)
        })
        Object.entries(userData).forEach(([k, v]) => {
          const el = {[`f_${k}`]: v}
          Object.assign(fData, el)
        })
      resolve({ ...mData, ...fData }), (err) => {
        reject(err)
      }
    })
  }

  #calculMatchPercentage(data) {
    return new Promise((resolve, reject) => {
      const resource = 'match_percentage'

      this.#showEmailInput()

      this.#request(resource, data).then((resp) => {
        this.score = resp.match_percentage
        resolve(resp.match_percentage)
      }, (err) => {
        reject(err)
      })
    })
  }

  #showEmailInput() {
    this.scoreContainerTarget.innerHTML = `<form id="myform">
      <div class="form-group form-score">
        <input type="text" class="single-line" id="email" data-visitor-score-target="email" placeholder="&#xf2bd; entre ton adresse email" style="font-family:Arial, Fontawesome">
      </div>
      <p>*en entrant ton adresse email, tu acceptes de recevoir l’actualité d’Astrocrush. Tu pourras te désinscrire à tout moment en cliquant sur le lien correspondant en fin d’email ou sur simple demande à contact@astrocrush.com.</p>
      <div class="form-group">
        <button type="button" class="btn landing-btn-ghost" data-action="click->visitor-score#showScore">Connaître mon score</button>
      </div>
    </form>`
  }

  showScore(e) {
    e.preventDefault()

    if (this.emailTarget.value === '' || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.emailTarget.value)) {
      Swal.fire({
        title: 'Veuillez renseigner une adresse email valide',
        icon: 'warning',
        confirmButtonColor: '#FFBABA',
        confirmButtonText: 'Ok'
      })
    } else {
      this.#saveMail(this.emailTarget.value, this.score)

      this.scoreContainerTarget.innerHTML = `<div">
        <h1 class="theme-info">Ton score de </br> compatibilité* avec <div class='theme-sign'>${this.#capitalizeFirstLetter(this.crushName)}</div> est de <div class='theme-sign'>${this.score} %</div></h1>
        <h3 class="theme-info">${this.warning()}</h3>
        <button type="button" data-action="click->visitor-score#redirectToScore" class="btn landing-btn-ghost mb-2">Calcule un nouveau score</button>
        <button type="button" data-action="click->visitor-score#redirectToTheme" class="btn landing-btn-ghost mb-2">Calcule mon thème astral</button>
        <button type="button" data-action="click->visitor-score#redirectToRoot" class="btn landing-btn-ghost">Retourne à l'accueil</button>
      </div>`
    }
  }

  warning() {
    if (this.score >= 75) {
      return "* C'est un super score, bravo ! ♥ Mais ce ne sont pas les meilleurs scores qui font les meilleures histoires. Maintenant il va falloir travailler avec ton crush pour construire une relation solide."
    } else if (this.score < 75 && this.score >= 50) {
      return '* Quel score honorable, félicitations. ☼ Maintenant il va falloir travailler avec ton crush pour construire une relation solide.'
    } else if (this.score < 50 && this.score >= 25) {
      return "* Pas si mal ! ☺ Il faudra sans doute redoubler d'efforts, mais un tel score n'a pas empêché certains couples de s'aimer très fort."
    } else if (this.score < 25) {
      return "* Ce score peut paraître bas, c'est certain, mais un tel score n'a pas empêché certains couples de s'aimer très fort. Il faudra sans doute redoubler d'efforts et d'attentions pour faire marcher votre histoire."
    }
  }

  #saveMail(email, score) {

    const name = this.name
    const url = this.saveUrlValue
    let data = new FormData();

    data.append("pre_user[username]", name)
    data.append("pre_user[email]", email)
    data.append("data[crushName]", this.crushName)
    data.append("data[score]", score)

    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: data
    }

    fetchWithToken(url, options)
  }

  #capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  redirectToScore() {
    window.location = this.scoreUrlValue
  }

  redirectToTheme() {
    window.location = this.themeUrlValue
  }

  redirectToRoot() {
    window.location = this.rootUrlValue
  }

  #request(resource, data) {
    const userId = this.userIdValue
    const apiKey = this.apiKeyValue
    return $.ajax({
      url: "https://json.astrologyapi.com/v1/"+resource,
      method: "POST",
      dataType:'json',
      headers: {
        "authorization": "Basic " + btoa(`${userId}:${apiKey}`, 'utf8').toString('base64'),
        "Content-Type":'application/json'
      },
      data: JSON.stringify(data)
    })
  }
}
