import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const cookieAllowed = this.#getCookie('allow_cookies')

    if (!cookieAllowed) this.#displayCookies()
  }

  #displayCookies() {
    this.element.classList.remove('d-none')
  }

  accept() {
    this.#allowCookies(true)
  }

  reject() {
    this.#allowCookies(false)
  }

  #allowCookies(allow) {
    if (allow) {
      this.#setCookie('allow_cookies', true, 365)
    } else {
      this.#setCookie('allow_cookies', false, 365)
    }

    this.element.classList.add('d-none')
  }

  #setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  #getCookie(cname) {
    let name = cname + "="
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length) === "true";
      }
    }
    return false;
  }
}
