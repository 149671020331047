import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    className: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".sortable-handle",
      filter: '.filtered',
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    const url = event.item.dataset.sortableUrl
    if (!url) return

    const data = new FormData()
    data.append('position', event.newIndex + 1)
    const options = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
      },
      body: data
    }

    fetchWithToken(url, options)
  }
}
