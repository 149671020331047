export class CardSwiper {
  constructor(attributes) {
    this.cards            = attributes.cards || []
    this.preloadCount     = attributes.preloadCount || 3
    this.numOfCards       = this.cards.length
    this.animating        = false
    this.threshold        = 125
    this.pullDeltaX       = 0
    this.deg              = 0
    this.loaded           = []
    this.cached           = []
    this.swiped           = []
    this.last             = this.numOfCards <= 1
    this.likedCallback    = attributes.likedCallback
    this.dislikedCallback = attributes.dislikedCallback
    this.nextCardPath     = attributes.nextCardPath
    this.init()
  }

  init() {
    if (this.cards.length === 0) return
    this.container = this.cards[0].parentElement

    this.cacheOverload()
  }

  cacheOverload() {
    this.loaded   = this.cards.slice(-this.preloadCount)
    const toCache = this.cards.slice(0, -this.preloadCount)
    this.cached   = toCache.map((e) => { return e.cloneNode(true) })
    toCache.forEach(e => e.remove())
    this.updateCurrentCard()
  }

  async fetchNextCard() {
    const presentIds = [...this.cached.map(e => e.dataset.id), ...this.loaded.map(e => e.dataset.id), ...this.swiped.map(e => e.dataset.id)]
    const url = `${this.nextCardPath}?present_ids=${presentIds}`
    const options = {
      method: 'GET',
      headers: { 'Accept': 'application/json' }
    }

    return fetchWithToken(url, options)
  }

  addCard(card) {
    const lastCard = this.loaded[0]
    this.loaded.splice(0, 0, card)
    this.container.insertAdjacentElement('afterbegin', card)
  }

  addNextCard() {
    if (this.cached.length > 0) {
      this.addCard(this.cached.pop())
    } else {
      this.fetchNextCard()
        .then(response => response.json())
        .then((data) => {
          if (data.html) {
            this.addCard(this.nodeFromString(data.html))
          }
        })
    }
  }

  nodeFromString(string) {
    const div = document.createElement('div')
    div.innerHTML = string
    return div.firstElementChild
  }

  addListenersToCurrentCard() {
    var callback = this.currentCardEventsCallback.bind(this)
    this.currentCard.addEventListener('mousedown', callback)
    this.currentCard.addEventListener('touchstart', callback)
  }

  updateCurrentCard() {
    this.currentCard      = this.loaded.slice(-1)[0]
    this.last             = this.loaded.length === 1
    if (this.currentCard) {
      this.addListenersToCurrentCard()
    } else {
      console.warn('no more cards')
    }
  }

  currentCardEventsCallback(e) {
    if (this.animating) return

    this.card       = this.currentCard
    this.cardReject = this.card.querySelector('.card_swiper__card__choice.m--reject')
    this.cardLike   = this.card.querySelector('.card_swiper__card__choice.m--like')
    this.startX     = e.pageX || e.touches[0].pageX

    var moveCallback = this.moveEventsCallback.bind(this)
    var endCallback  = this.endEventsCallback.bind(this)

    this.moveListener = new AbortController();
    this.endListener  = new AbortController();

    document.addEventListener('mousemove', moveCallback, { signal: this.moveListener.signal })
    document.addEventListener('touchmove', moveCallback, { signal: this.moveListener.signal })
    document.addEventListener('mouseup',  endCallback, { signal: this.endListener.signal })
    document.addEventListener('touchend', endCallback, { signal: this.endListener.signal })
  }

  moveEventsCallback(e) {
    var x = e.pageX || e.touches[0].pageX
    this.pullDeltaX = (x - this.startX)
    if (!this.pullDeltaX) return
    this.pullChange()
  }

  endEventsCallback() {
    this.moveListener.abort()
    this.endListener.abort()
    if (!!this.pullDeltaX) {
      this.release()
    } else {
      this.navigateToProfile()
    }
  }

  pullChange() {
    this.animating = true
    this.deg       = this.pullDeltaX / 10
    this.currentCard.style.transform = `translateX(${this.pullDeltaX}px) rotate(${this.deg}deg)`

    var opacity       = this.pullDeltaX / 100
    var rejectOpacity = (opacity >= 0) ? 0 : Math.abs(opacity)
    var likeOpacity   = (opacity <= 0) ? 0 : opacity

    this.cardReject.style.opacity = rejectOpacity
    this.cardLike.style.opacity   = likeOpacity
  }

  release() {
    if (this.pullDeltaX >= this.threshold) {
      this.currentCard.classList.add("to-right")
      var like = true
    } else if (this.pullDeltaX <= -this.threshold) {
      this.currentCard.classList.add("to-left")
      var like = false
    }

    if (Math.abs(this.pullDeltaX) >= this.threshold) {

      like ? this.likedCallback(this.currentCard.dataset.id) : this.dislikedCallback(this.currentCard.dataset.id)
      this.cardSwiped()
    } else {
      this.currentCard.classList.add("reset")
    }

    setTimeout(() => {
      if (this.currentCard) {
        this.currentCard.setAttribute("style", "")
        this.currentCard.classList.remove("reset")
        this.currentCard.querySelectorAll(".card_swiper__card__choice").forEach((e) => { e.setAttribute("style", "") })
      }

      this.pullDeltaX = 0
      this.animating  = false
    }, 300)

  }

  cardSwiped() {
    this.currentCard.classList.add("swiped")
    setTimeout(() => {
      this.swiped.splice(0, 0, this.loaded.pop())
      this.swiped = this.swiped.map(e => this.cloneAndRemove(e))
      this.addNextCard()
      this.updateCurrentCard()
    }, 300)
  }

  cloneAndRemove(element) {
    const clone = element.cloneNode(true)
    element.remove()
    return clone
  }

  navigateToProfile() {
    this.currentCard.querySelector('.profile-link').click()
  }

  like() {
    this.currentCard.classList.add('to-right')
    this.cardSwiped()
    this.likedCallback(this.currentCard.dataset.id)
  }

  dislike() {
    this.currentCard.classList.add("swiped", 'to-left')
    this.cardSwiped()
    this.dislikedCallback(this.currentCard.dataset.id)
  }
}

global.CardSwiper = CardSwiper
