import { Controller } from 'stimulus';
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = ['infos']
  static values = { id: Number }

  connect() {
    this._broadcast()
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "Onboarding::SignCalculationChannel", id: this.idValue },
      { received: this._received.bind(this) }
    )
  }
  _received(data) {
    this.infosTarget.classList.add('fade-out-in')
    setTimeout(() => { this.infosTarget.innerHTML = data.html }, 750)
    setTimeout(() => { this.infosTarget.classList.remove('fade-out-in') }, 1500)
  }
}
