import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    'addButton',
    'form',
    'fakeInput',
    'photoContainer',
    'deleteButton',
    'onboardingSubmitButton'
  ]
  static values =  {
    gridPhotoHtml: String
  }

  connect() {
    this.reader = new FileReader();
  }

  chooseFiles() {
    this.fakeInputTarget.click()
  }

  addPhotos() {
    Array.from(this.fakeInputTarget.files).forEach(this.#addPhoto.bind(this))
    this.fakeInputTarget.value = null
  }

  delete(event) {
    var target = event.currentTarget
    Swal.fire({
      title: I18n.t('photos.delete_alert.title'),
      text: I18n.t('photos.delete_alert.text'),
      icon: 'warning',
      confirmButtonColor: '#FFBABA',
      confirmButtonText: I18n.t('photos.delete_alert.confirm_button_text'),
      showCancelButton: true,
      cancelButtonText: I18n.t('photos.delete_alert.cancel_button_text')
    }).then((result) => {
      if (result.isConfirmed) this.#requestDelete(target, event.params)
    })
  }

  #requestDelete(target, params) {
    if (params.url) {
      const url = params.url
      const options = {
        method: 'DELETE',
        headers: { 'Accept': 'application/json' }
      }

      fetchWithToken(url, options)
        .then(response => response.json())
        .then((data) => {
          if (data.destroyed) {
            const photo = this.photoContainerTargets.find((e) => e.dataset.id == data.id)
            photo.remove()
            this.#toggleDeleteButton()
            this.#toggleOnboardingSubmitButton()
          } else {
            Swal.fire({
              title: I18n.t('photo_was_not_destroyed'),
              text: data.errors,
              icon: 'warning',
              confirmButtonColor: '#FFBABA',
              confirmButtonText: 'Ok'
            })
          }
        })
    } else {
      const photo = target.closest('.photo_grid__photo_container')
      photo.remove()
      this.#toggleDeleteButton()
      this.#toggleOnboardingSubmitButton()
    }
  }

  #addPhoto(file, index) {
    const html = this.#createGridPhotoHTML()
    this.#showGridPhotoOverlay(html)
    this.#displayFileInHTML(file, html)
    // lui mettre sa position

    this.#uploadFile(file, index, html)
    this.#insertNewGridPhotoHTML(html)
  }

  #createGridPhotoHTML(file) {
    const tmp     = document.createElement('div')
    tmp.innerHTML = this.gridPhotoHtmlValue
    return tmp.firstElementChild
  }

  #displayFileInHTML(file, html) {
    const reader  = new FileReader();
    reader.onload = ev => html.querySelector('img').src = ev.currentTarget.result;
    reader.readAsDataURL(file)
  }

  #uploadFile(file, index, html) {
    const url    = `/rails/active_storage/direct_uploads`
    const progressDisplayer = new GridPhotoProgressDisplayer(html)
    const upload = new DirectUpload(file, url, progressDisplayer)
    upload.create((error, blob) => this.#handleUploadFile(error, blob, index, html))
  }

  #insertNewGridPhotoHTML(html) {
    this.addButtonTarget.insertAdjacentElement('beforebegin', html)
  }

  #handleUploadFile(error, blob, index, html) {
    if (error) {
      html.remove()
      Swal.fire({
        title: I18n.t('error_uploading_photo'),
        text: error,
        icon: 'warning',
        confirmButtonColor: '#FFBABA',
        confirmButtonText: 'Ok'
      })
    } else {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.id);
      hiddenField.name = `user[blob_ids][id_${index + 1}]`
      html.appendChild(hiddenField)
      this.#hideGridPhotoOverlay(html)
      this.#toggleDeleteButton()
      this.#toggleOnboardingSubmitButton()
    }

  }

  #showGridPhotoOverlay(html) {
    html.querySelector('.photo_grid__photo_container__upload_progress_overlay').style.opacity = 1
  }

  #hideGridPhotoOverlay(html) {
    html.querySelector('.photo_grid__photo_container__upload_progress_overlay').style.opacity = 0
  }

  #toggleDeleteButton() {
    if (this.photoContainerTargets.length > 1) {
      this.#displayElements(this.deleteButtonTargets)
    } else {
      this.#hideElements(this.deleteButtonTargets)
    }
  }

  #toggleOnboardingSubmitButton() {
    if (!this.hasOnboardingSubmitButtonTarget) return

    this.onboardingSubmitButtonTarget.disabled = this.photoContainerTargets.length < 1
  }

  #displayElements() {
    Array.from(arguments).flat(Infinity).forEach((target) => {
      target.classList.remove('d-none')
    })
  }

  #hideElements() {
    Array.from(arguments).flat(Infinity).forEach((target) => {
      target.classList.add('d-none')
    })
  }
}

// ------------------------------------------------------------------------------------------------

class GridPhotoProgressDisplayer {
  constructor(html) {
    this.progress = html.querySelector('.photo_grid__photo_container__upload_progress')
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", this.#directUploadDidProgress.bind(this))
  }

  #directUploadDidProgress(event) {
    const percentage = Math.round((event.loaded / (event.total)) * 100)
    this.progress.innerText = `${percentage} %`
  }
}


// import { Controller } from '@hotwired/stimulus'
// import Swal from 'sweetalert2'

// export default class extends Controller {
//   static targets = [
//     'input',
//     'photo',
//     'cancelButton',
//     'deleteButton',
//     'saveButton'
//   ]

//   static values = {
//     updateUrl: String,
//     originalImageSrc: String,
//     defaultImageSrc: String,
//     class: String,
//     style: String,
//     detachUrl: String,
//     persisted: Boolean
//   }

//   connect() {
//     console.log('lololo')
//     this.#setup()
//   }

//   #setup() {
//     this.deleted  = false
//     if (this.inputTarget.files[0])  this.displayImage()
//     if (this.persistedValue)        this.deleteButtonTarget.classList.remove('d-none')
//   }

//   displayImage() {
//     this.reader.onload = (ev) => {
//       this.photoTarget.src = ev.currentTarget.result
//       this.#showElements(this.cancelButtonTarget, this.saveButtonTarget)
//     }
//     this.reader.readAsDataURL(this.inputTarget.files[0])
//   }

//   openFileExplorerWindow() {
//     this.inputTarget.click()
//   }

//   dropFile(event) {
//     if (event.dataTransfer.items) {
//       var file = event.dataTransfer.items[0].getAsFile()
//     } else {
//       var file = event.dataTransfer.files[0]
//     }
//     var list = new DataTransfer();
//     list.items.add(file)

//     this.inputTarget.files = list.files
//   }

//   cancel() {
//     this.photoTarget.src = this.deleted ? this.defaultImageSrcValue : this.originalImageSrcValue
//     this.inputTarget.value = ''
//     this.#hideElements(this.cancelButtonTarget, this.saveButtonTarget)
//   }

//   save(event) {
//     console.log(event)
//     this.saveButtonTarget.classList.add('active')
//     const url = this.updateUrlValue
//     const data = new FormData
//     data.append('photo[file]', this.inputTarget.files[0])
//     data.append('photo[class]', this.classValue)
//     data.append('photo[style]', this.styleValue)
//     const options = {
//       method: 'PATCH',
//       headers: {
//         'Accept': 'application/json'
//       },
//       body: data
//     }

//     this.saveButtonTarget.classList.add('active')
//     this.#fetchWithToken(url, options)
//       .then(response => response.json())
//       .then((data) => {
//         this.saveButtonTarget.classList.remove('active')
//         if (data.html) this.element.outerHTML = data.html
//       })

//   }

//   delete({ params }) {
//     Swal.fire({
//       title: `Do you want to remove the attached photo on this image?`,
//       text: `You will permanently remove the photo and the image will be empty`,
//       icon: 'warning',
//       confirmButtonColor: '#FFBABA',
//       confirmButtonText: 'Remove',
//       showCancelButton: true,
//       cancelButtonText: 'Cancel removal'
//     }).then((result) => {
//       if (result.isConfirmed) this.#requestDelete(params.detachUrl)
//     })
//   }

//   #requestDelete(url) {
//     const data = new FormData
//     data.append('photo[class]', this.classValue)
//     data.append('photo[style]', this.styleValue)
//     const options = {
//       method: 'DELETE',
//       headers: {
//         'Accept': 'application/json'
//       },
//       body: data
//     }

//     this.deleteButtonTarget.classList.add('active')
//     this.#fetchWithToken(url, options)
//       .then(response => response.json())
//       .then(this.#handleDeletion.bind(this))
//   }

//   #fetchWithToken(url, options) {
//     options.headers = {
//       "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
//       ...options.headers
//     };

//     return fetch(url, options)
//   }

//   #handleDeletion(data) {
//     if (data.html) this.element.outerHTML = data.html
//   }

//   #showElements() {
//     Array.from(arguments).flat().forEach((e) => {
//       e.classList.remove('hidden')
//     })
//   }

//   #hideElements() {
//     Array.from(arguments).flat().forEach((e) => {
//       e.classList.add('hidden')
//     })
//   }
// }
