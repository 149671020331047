import { Controller } from "stimulus"
import Masonry from 'masonry-layout'

export default class extends Controller {
  connect() {
    this.initMasonry()
  }

  initMasonry() {
    new Masonry(this.element, {
      columnWidth: '.article-sizer',
      itemSelector: '.article',
      percentPosition: true,
      gutter: 20,
    });
  }
}
