import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    id: Number
  }

  connect() {
    this.likeEvent = new CustomEvent("card_swiper:liked", { detail: this.idValue })
    this.dislikeEvent = new CustomEvent("card_swiper:disliked", { detail: this.idValue })
  }

  like() {
    window.modal.close()
    setTimeout(() => {
      document.dispatchEvent(this.likeEvent)
    }, 500)
  }

  dislike() {
    window.modal.close()
    setTimeout(() => {
      document.dispatchEvent(this.dislikeEvent)
    }, 500)
  }
}
