import { Controller } from "@hotwired/stimulus"
import { Modal } from "../components/modal"

export default class extends Controller {
  static targets = ["card", "modal", "modalBody"]
  static values = {
    nextCardPath: String
  }

  connect() {
    this.initSwiper()
    this.initModal()
    document.addEventListener('card_swiper:liked', this.like.bind(this))
    document.addEventListener('card_swiper:disliked', this.dislike.bind(this))
  }

  disconnect() {
    document.removeEventListener('card_swiper:liked', this.like.bind(this))
    document.removeEventListener('card_swiper:disliked', this.dislike.bind(this))
  }

  initSwiper() {
    this.cardSwiper = new CardSwiper({
      cards: this.cardTargets,
      nextCardPath: this.nextCardPathValue,
      likedCallback: this.swipeRight.bind(this),
      dislikedCallback: this.swipeLeft.bind(this)
    })
  }

  initModal() {
    this.modal = new Modal()
  }

  hideModal() {
    this.modal.hide()
  }

  swipeLeft(id) {
    this.requestMatch(false, id)
  }

  swipeRight(id){
    this.requestMatch(true, id)
  }

  like() {
    this.cardSwiper.like()
  }

  dislike() {
    this.cardSwiper.dislike()
  }

  requestMatch(liked, id) {
    const url  = `/matches`
    const data = new FormData()

    data.append('match[mate_id]', id)
    data.append('liked', liked)

    const options = {
      method: "POST",
      body: data
    }

    fetchWithToken(url, options)
      .then(response => response.json())
      .then(this.responseHandler.bind(this))
  }

  responseHandler(data) {
    if (data.content) {
      this.modal.replaceContent({html: data.content})
      this.modal.open()
    }
  }
}
